const experience = [
  {
    id: 1,
    name: 'Demis Group / Энергопроф',
    title: 'SEO-специалист',
    start: 'Октябрь 2011',
    end: 'Июль 2020',
    text: [
      `Комплексная проработка сайтов различных тематик с целью
      вывода их в топ поисковой выдачи.`,
    ],
  },
  {
    id: 2,
    name: 'Фриланс',
    title: 'HTML-верстальщик',
    start: 'Сентябрь 2021',
    end: 'Июнь 2023',
    text: [
      `Кроссплатформенная, кроссбраузерная адаптивная вёрстка различной сложности с применением методологии БЭМ. В качестве препроцессора использовался Sass (синтаксис SCSS). Настройка сборки с помощью Webpack.`,
      `Натяжка вёрстки и доработка тем для WordPress.`,
      `Создание одностраничных сайтов с помощью React и Vue.`,
    ],
  },
  {
    id: 3,
    name: 'IBS',
    title: 'HTML-верстальщик / Frontend-разработчик',
    start: 'Июнь 2023',
    end: 'Сентябрь 2023',
    text: [
      `Работа над разделами корпоративного портала на Битрикс 24`,
      `1. Раздел проведения опросов о мероприятиях и прочих активностях:`,
      `• вёрстка макетов из Figma с использованием шаблонизатора Twig и препроцессора Stylus;`,
      '• добавление интерактивности с помощью нативного JavaScript и библиотеки jQuery : загрузка изображений, добавление/удаление новых пунктов в опросы и т.п.',
      '2. Раздел с конструктором лэндингов:',
      '• создание блоков для конструктора лэндингов: вёрстка из Figma, создание файлов манифеста с описанием редактируемых частей блока;',
      '• доработка существующих блоков;',
      '3. База презентаций:',
      '• правка существующей верстки.',
    ],
  },
  {
    id: 4,
    name: 'REMONT.IO',
    title: 'HTML-верстальщик / Frontend-разработчик',
    start: 'Декабрь 2023',
    end: 'Апрель 2024',
    text: [
      `• Верстка макетов в формате figma/psd под дисплеи различной ширины.`,
      `• Дополнение новым функционалом уже имеющийся код.`,
      `• Рефакторинг и исправление проблем в уже имеющемся коде.`,
      `• Перенос лендинга с WordPress на HTML, SCSS, JS с целью ускорения загрузки и объединения двух версий, мобильной и десктопной, в одну.`,
      `• Замена сторонних плагинов и библиотек собственными решениями на JavaScript с возможностью переиспользования (попапы, слайдеры, валидация и отправка форм и пр.).`,
    ],
  },
  {
    id: 5,
    name: 'BPMSoft (Ланит Омни)',
    title: 'HTML-верстальщик',
    start: 'Апрель 2024',
    end: 'настоящее время',
    text: [
      `• Вёрстка новых страниц и компонентов.`,
      `• Доработка и модернизация текущей вёрстки.`,
      `• Анализ и оценка технических решений для настройки и разработки требуемой функциональности.`,
    ],
  },
];

export default experience;
