const menu = [
  {
    id: 1,
    url: 'about',
    anchor: 'Обо мне',
  },
  {
    id: 2,
    url: 'projects',
    anchor: 'Проекты',
  },
  {
    id: 3,
    url: 'skills',
    anchor: 'Навыки',
  },
  {
    id: 4,
    url: 'experience',
    anchor: 'Опыт',
  },
  {
    id: 5,
    url: 'contacts',
    anchor: 'Контакты',
  },
];
export default menu;
