const contacts = [
  {
    id: 1,
    name: 'telegram',
    title: '@a_vhlv',
    url: 'https://t.me/a_vhlv',
  },
  {
    id: 2,
    name: 'email',
    title: 'job@vhlv.ru',
    url: 'mailto:job@vhlv.ru',
  },
  {
    id: 3,
    name: 'github',
    title: 'ProjectOnGitHub',
    url: 'https://github.com/ProjectOnGitHub',
  },
];

export default contacts;
